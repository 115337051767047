<template>
  <div id="app">
    <template v-if="!isAgeVerified">
      <AgeBanner @isClick="isAgeVerified = true"/>
    </template>
    <template v-else>
      <router-view name="header"></router-view>
      <router-view/>
      <router-view name="footer"></router-view>
    </template>
  </div>
</template>
<script>
import AgeBanner from "./components/HomePage/AgeBanner.vue";
import Cookies from "js-cookie";
// import { mapActions, mapState } from "vuex";

export default {
  components: { AgeBanner },
  data() {
    return {
      isAgeVerified: false,
    };
  },
  mounted() {
    // this.getList();
    this.ageVerification();
  },
  computed: {
    // ...mapState('settings', ['settingsOutput']),

    // getSettings(){
    //   return this.settingsOutput && this.settingsOutput.data ? this.settingsOutput.data : null;
    // }

  },
  methods:{
    // ...mapActions('settings', ['getList']),

    ageVerification() {
      let getCookie = Cookies.get("ageVerified");
      getCookie ? this.isAgeVerified = true : this.isAgeVerified = false;
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Ruda');

#app {
  font-family: 'Ruda', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.cursor-pointer
{
  cursor: pointer;
}
/* primary color */
.primary-color {
  color: #e3e1dd;
}
.secondary-color {
  color: #6c4a3a;
}
/* text-dec none */
.text-dec-none{
  text-decoration: none;
}
.overflow-wrap{
  overflow-wrap: break-word;
}    
.btn-border-white,
.btn-border {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 45px;
  padding-right: 32px;
  padding-left: 32px;
  font-weight: bold;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
/* button homepage */
.btn-border-white {
  border: 2px solid #fff;
  color: #fff;
}
/* button other oages */
.btn-border {
  border: 2px solid #6c4a3a;
  color: #6c4a3a;
}
.btn-border-white:hover {
  color: #6c4a3a;
  background-color: #fff;
}
.btn-border:hover {
  color: #fff;
  background-color: #6c4a3a;
}
.disabled
{
  pointer-events: none;
  opacity: 0.8;
}
</style>

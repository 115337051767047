<template>
    <div class="prizes">
        <div class="container">
            <h1 class="secondary-color mt-3 mb-5">{{$t("premios.title").toUpperCase() }}</h1>
            <vueper-slides
                v-if="prizes.length"
                fixed-height="30vh"
                autoplay
                class="no-shadow"
                :visible-slides="3"
                :slide-ratio="1 / 4"
                :arrows="false"
                :bullets="false"
                :touchable="false"
                :breakpoints="{
                    1030: { visibleSlides: 2, touchable: true },
                    575: { visibleSlides: 1, arrows: false},
                }"
            >
            <vueper-slide v-for="(prize, i) in prizes" :key="i">
                <template v-slot:content>
                    <PrizesThumb :prizesContent="prize" />
                </template>
            </vueper-slide>
            </vueper-slides>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import PrizesThumb from '@/components/HomePage/prizes/PrizesThumb.vue';
import InputGetList from '@/classes/InputGetList';
import { mapState, mapActions } from 'vuex';

export default {
    components: {VueperSlides, VueperSlide, PrizesThumb},
    mounted () {
        this.initializePage();
    },
    computed: {
        ...mapState({ 
            prizesOutput: state => state.prizes.listOutput,
        }),
        prizes(){
            return this.prizesOutput && this.prizesOutput.data ? this.prizesOutput.data : [];
        }
    },
    methods: {
        ...mapActions('prizes', ['getList']),

        initializePage() {
            
            // GET list of prizes
            const itemsPerPage = 10;
            const currentPage = 1;
            const sorter = 1 //;
            const propertyName = "id";
            const searchText = '';

            // Make request
            var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
            this.getList(input);
        }
    },
};
</script>

<style scoped>
.prizes {
  background-color: #e3e1dd;
  padding-top: 50px;
  padding-bottom: 50px;
}
>>>.vueperslide {
  transition: .3s ease-in-out;
  transform: scale(0.9);
}
>>>.vueperslide--active{
    transform: scale(1.3);
}
</style>

<template>
    <div class="vueperslides__track-inner">
        <div class="vueperslide">
            <div class="vueperslide__content-wrapper">
                <div class="card-prize">
                    <div class="vueperslide__title">
                        <h5>{{prizesContent.year}}</h5>
                    </div>
                    <div class="vueperslide__content">
                        <h3>{{prizesContent.title}}</h3><span>Revista exemplo</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        prizesContent: {
            type: Object
        }
    }
}
</script>

<style scoped>
.card-prize{
    width: auto;
    height: 80%;
}
>>>.vueperslide__title{
    text-align: left;
    color: #6c4a3a;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 8px;
}
>>>.vueperslide__content{
    color: #2b2b2b;
    text-align: left;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
    max-width: 270px;
}
.vueperslide__content span {
    display: block;
    font-size: 14px;
    line-height: 24px;
}
</style>
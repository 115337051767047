<template>
    <section>
        <LogoLoading v-if="!memberDetail"/>
        <div v-else class="container">
            <div class="row mt-5 mb-5">
                <div class="col-12 col-lg-6">
                    <div class="section-title">
                        {{memberDetail.name}}
                    </div>
                    <div class="section-desc" v-html="memberDetail.description"/>
                </div>
                <div class="col-12 col-lg-6 align-self-center">
                    <img loading=lazy class="img-fluid" :src="joinPaths(memberDetail.photoPath)" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import router from "@/router";
import { mapState, mapActions } from 'vuex';
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue"
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
    metaInfo () {
    var result =
    { 
        title: this.memberDetail ? `${this.memberDetail.name} - Calheiros Cruz` : "Calheiros Cruz", 
        htmlAttrs: {
            lang: this.$i18n.locale,
        },
        meta: 
        [
            {name: 'description', content: this.memberDetail && this.memberDetail.description ? UTILS_HELPER.htmlToText(this.memberDetail.description) : this.$t("intro")},
            {name: 'keywords', content: this.memberDetail && this.memberDetail.name && this.memberDetail.role ? this.memberDetail.name + ", " + this.memberDetail.role : this.$t("vueRoutes.defaultKeywords")},
              // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.memberDetail ? `${this.memberDetail.name} - Calheiros Cruz` : "Calheiros Cruz"},
            {name: 'twitter:description', content: this.memberDetail && this.memberDetail.description ? UTILS_HELPER.htmlToText(this.memberDetail.description) : this.$t("intro")},
            {name: 'twitter:image', content: this.featuredImage},
            //Facebook OpenGraph
            {property: 'og:title', content: this.memberDetail ? `${this.memberDetail.name} - Calheiros Cruz` : "Calheiros Cruz"},
            {property: 'og:site_name', content: 'Calheiros Cruz'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content:  this.featuredImage},
            {property: 'og:description', content: this.memberDetail && this.memberDetail.description ? UTILS_HELPER.htmlToText(this.memberDetail.description) : this.$t("intro")},
        ]
    } 

    if(this.$route.params.memberSlug)
    {
        result.link = 
        [
            {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.memberSlug])}`},
            {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.memberSlug])}`},
        ];
    }
    return result;
  }, 
    components: {
        LogoLoading,
    },
    data() {
        return {
            memberDetail: null
        }
    },
    mounted() {
        this.initializePage();
    },
    computed: {
        ...mapState({ 
            memberOutput: state => state.members.detailOutput,
        }),
        featuredImage(){
            return this.memberDetail && this.memberDetail.photoPath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.memberDetail.photoPath]) : null;
        }
    },
    methods: {
        ...mapActions('members', ['getDetails']),

        initializePage() {
            if (!this.$route.params.memberSlug) {
                router.push({ name: "HomePage" });
            }
            else{
                this.getDetails(this.$route.params.memberSlug);
            }
        },
        joinPaths(relativePath){
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },

    },
    watch: {
        $route(to) {
            if(to.name == "TeamDetail")
            {
                this.$router.go(0);
            }
        },
        memberOutput: function(val)
        {
            if(val != null)
            {
                if(val && val.data)
                {
                    // console.log(val.data)
                    this.memberDetail = val.data;
                }
                else
                {
                    router.push("/");
                }
            }
        }
    },
}
</script>

<style scoped>
.container{
    padding-top: 130px;
}
.section-title {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: #6c4a3a;
  text-align: left;
}
.section-desc {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 32px;
    color: #575757;
    text-align: left;
}

</style>
<template>
    <div class="container">
        <h1 class="secondary-color py-3 mt-5 mb-5">{{$t("team.title").toUpperCase() }}</h1>
        <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-4 mt-4 cursor-pointer" @click="sendClick(index)" v-for="(member,index) in members" :key="index">
                <div class="card-picture-form">
                    <img loading=lazy :src="joinPaths(member.photoPath)" class="img-fluid" alt="">
                </div>
                <div class="card-body mt-3">
                    <h5 class="card-title">{{member.name}}</h5>
                    <p class="card-text">{{member.role}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import InputGetList from '@/classes/InputGetList';
import { API_HELPER } from "@/helpers/api.js";

export default {
    mounted () {
        this.initializePage();
    },
    computed: {
        ...mapState({ 
            membersOutput: state => state.members.listOutput,
        }),
        members(){
            return this.membersOutput && this.membersOutput.data ? this.membersOutput.data : null;
        }
    },
    methods: {
        ...mapActions('members', ['getList']),

        sendClick(index){
            this.$router.push({name: 'TeamDetail', params: {member: this.members[index], memberSlug: this.members[index].slug}});
        },

        initializePage() {

            // GET list of Members
            const itemsPerPage = 10;
            const currentPage = 1;
            const sorter = 1 //;
            const propertyName = "id";
            const searchText = '';

             // Make request
            var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
            this.getList(input);
        },
        joinPaths(relativePath){
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        }
    },
}
</script>

<style scoped>
.img-fluid{
  width: 200px;
}
.card-picture-form{
  box-shadow: none;
  padding: 6px;
  border: 1px solid #ebebeb;
  border-color: #6c4a3a;
  border-radius: 50%;
  width: 200px;
  margin: 0 auto;

}
.card-picture-form img{
  border-radius: 50%;
  box-shadow: none;
  overflow: hidden;
  width: 200px;
  border: 1px solid #ebebeb;
}
.card-title{
    background-color: #6c4a3a;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
    font-weight: 700;

}
.card-body p{
    overflow-wrap: break-word;
    font-size: 16px;

}
</style>
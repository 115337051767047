<template>
  <div class="footer">
    <!-- <Newsletter/> -->
    <!-- <div v-if="this.$route.name == 'TeamDetail' || this.$route.name == 'WineDetail'" id="footer-bar" class="footer-bar"/> -->
    <div v-if="getSettings" class="container">
      <div class="row">
        <div class="col-12 col-lg-4 order-2 order-lg-1">
          <!-- <div
            class="
              d-lg-flex
              justify-content-center
              align-items-center
              h-100
              my-2 my-lg-0
            "
          >
            <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">
              <img
                src="@/assets/img/livro_reclamacoes.png"
                alt=""
                height="80px"
              />
            </a>
          </div> -->
        </div>
        <div class="col-12 col-lg-4 order-1 order-lg-2">
          <div class="contact d-flex flex-column py-4">
            <a class="clean-link" :href="`mailto:${getSettings.email}`">
              {{ getSettings.email }}
            </a>
            <p>
              <a class="clean-link" :href="`tel:${this.getSettings.phone}`">{{
                getSettings.phone
              }}</a
              ><br />{{ $t("callTo") }}
            </p>
            <a :href="getSettings.facebook" class="clean-link" target="_blank">
              <b-icon icon="facebook"></b-icon>
              {{ $t("findUs") }}
            </a>
          </div>
          <div class="acivr">
            <img
              v-if="this.$route.name == 'Contact'"
              src="../assets/img/ACIVR.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 col-lg-4 order-3 order-lg-2">
          <!-- <div
            class="
              d-flex
              justify-content-center
              align-items-center
              h-100
              my-2 my-lg-0
            "
          >
            <a
              href="https://www.cniacc.pt/pt/empresas-aderentes-ao-cniacc/excellus-unipessoal-lda-vila-real-23164"
              target="_blank"
            >
              <img src="@/assets/img/CNIACC.png" alt="" height="80px" />
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="container">
      <div class="copyrights mt-2 mt-lg-0">
        <div class="row">
          <div class="col-12 col-lg-4">
            <router-link
              class="clean-link"
              active-class="active"
              exact
              :to="{ name: 'StaticInfos', params: { slug: 'legal' } }"
              >{{ $t("legalInformation") }}</router-link
            >
          </div>
          <div class="col-12 col-lg-4">
            <a
              class="clean-link"
              href="/file.pdf"
              :download="$t('qualityPolicy')"
              >{{ $t("qualityPolicy") }}</a
            >
          </div>
          <div class="col-12 col-lg-4">
            <a href="https://www.adarme.pt/" class="clean-link" target="_blank"
              >POWERED BY © ADARME.PT</a
            ><br class="d-md-none" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Newsletter from '@/components/Newsletter.vue';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    // Newsletter
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState("settings", ["settingsOutput"]),
    getSettings() {
      return this.settingsOutput && this.settingsOutput.data
        ? this.settingsOutput.data
        : null;
    },
    // settingsStored: function(){
    //   return JSON.parse(localStorage.settings);
    // },
  },
  methods: {
    ...mapActions("settings", ["getList"]),
  },
};
</script>

<style scoped>
.footer {
  background-color: #e3e1dd;
}

.footer .contact {
  color: black;
  font-size: 16px;
  line-height: 30px;
}

.clean-link {
  color: inherit;
  text-decoration: none;
}

.copyrights {
  padding-top: 15px;
  padding-bottom: 15px;
}

.copyrights a {
  color: #6c4a3a;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
</style>
